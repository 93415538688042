<template>
  test
</template>

<script>
import { ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useRoute, useRouter } from 'vue-router';
import Axios from 'axios';
export default {
    setup () {
        const toast = useToast();
        const route = useRoute();
        const router = useRouter();
        const pageLoading = ref(true);
        const paginationValue = ref(10);
        const pageValue = ref();

        const bookings = ref();

        const getActiveQuerys = () => {
            if(route.query.page != null){
                pageValue.value = route.query.page;
            }
            if(route.query.pagination != null){
                paginationValue.value = parseInt(route.query.pagination);
            }
            // if(route.query.status != null){
            //     bookingStatus.value = route.query.status;
            // }
        };
        getActiveQuerys();

        const getBookingList = () => {
            Axios
                .get("api/operator/booking/list",{
                    params: {
                        page: pageValue.value,
                        pagination: paginationValue.value,
                        // tour_id: route.params.id,
                        tour_id: 5,
                        // status: bookingStatus.value,
                    }
                })
                .then((res) =>{
                    console.log(res.data);
                    if (res.data.response == "success") {
                        bookings.value = res.data.data;
                        bookings.value.from = bookings.value.from-1;
                        pageLoading.value = false;
                    }else{
                        showToast("error", "Error", res.data.message);
                    }
                })
                .catch((err) =>{
                    showToast("error", "Error", "Something went wrong. Try again!");
                    console.log(err);
                });
        };
        getBookingList();

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const onPage = (event) => {
			pageValue.value = event.page + 1;
			paginationValue.value = event.rows;
			pageLoading.value = true;

            var queryParams = {};
            if(pageValue.value != null){
                queryParams.page = pageValue.value;
            }
            if(paginationValue.value != null){
                queryParams.pagination = paginationValue.value;
            }
            router.replace({ name: 'booking', query: { ...queryParams } });

            getBookingList();
		}

        return {
            pageLoading,
            onPage,
            paginationValue,
            pageValue,
        }
    }
}
</script>

<style>

</style>